<template>
  <div>
    blank
  </div>
</template>

<script>

export default {
  components: {},
}
</script>

<style>

</style>
